const fbcfg = {
  apiKey: "AIzaSyAIwOaeqXqiJNqm_C5yvO_cWmm48fBiQNU",
  authDomain: "surfspotatlas.firebaseapp.com",
  databaseURL: "https://surfspotatlas.firebaseio.com",
  projectId: "surfspotatlas",
  storageBucket: "surfspotatlas.appspot.com",
  messagingSenderId: "348127454502",
  appId: "1:348127454502:web:4870568f0f2bab6da2cde7",
}

export default fbcfg;
