export const waveAttributeHeadings = {
  textAlign: "center",
  color: "#616161",
  fontSize: "1.5rem",
  fontWeight: 600
}

export const mapNavStyle = {
  position: 'absolute',
  top: 20,
  left: 0,
  padding: '10px'
}
