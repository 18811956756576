export const FIREBASE_BUCKET = "gs://surfspotatlas.appspot.com/";
export const monthToDayConversion = {
  january: 1,
  february: 31,
  march: 61,
  april: 91,
  may: 121,
  june: 151,
  july: 181,
  august: 211,
  september: 241,
  october: 271,
  november: 301,
  december: 331
}
